<!--
 * @Author: lzh
 * @Date: 2022-12-29 17:54:40
 * @LastEditors: lbh
 * @LastEditTime: 2023-07-13 17:59:14
 * @Description: file content
-->
<template>
  <div
    class="QR_code_order_image_text1_box px-QR_code_order_image_text1_box"
    :style="`
        padding-top: ${configs.paddingTop}px;
    padding-bottom: ${configs.paddingBottom}px;
    background-color:${configs.backgroundColor};
  `"
    @click="clickItem('')"
  >
    <h1
      class="title"
      :class="`edit_${Xindex}_title`"
      v-html="$util.rex.getHtml(configs[`${nowL}title`] || configs.title)"
    ></h1>
    <h2
      class="desc"
      v-html="$util.rex.getHtml(configs[`${nowL}desc`] || configs.desc)"
      :class="`edit_${Xindex}_desc`"
    ></h2>
    <div class="echarts-box">
      <div
        class="echarts1"
        @click.stop="clickItem('items',1)"
      >
        <div
          id="main1"
          :style="`width: calc(100vw / 2.89); 
          height: calc(100vw / 2.89)`"
        ></div>
        <div class="echarts1-bg">
          <div class="px-circle"></div>
          <div class="child1"></div>
          <div class="child2"></div>
        </div>
      </div>
      <div
        class="echarts2"
        @click.stop="clickItem('items',2)"
      >
        <div
          id="main2"
          :style="`width: calc(100vw / 2.89); 
          height: calc(100vw / 2.89)`"
        ></div>
        <div class="echarts2-bg">
          <div class="child"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import langMixin from '../../mixins/lang';
import * as echarts from 'echarts';
import selfEditQRCodeOrderImageText1 from '../../components/edit/QRCodeOrderImageText1.vue'
import _lodash from "lodash";
export default {
  name: "selfQRCodeORderImageText1",
  components: {
    selfEditQRCodeOrderImageText1
  },
  mixins: [langMixin],
  props: {
    Xindex: {
      default () {
        return '';
      },
    },
    configs: {
      default () {
        return {
        }
      }
    }
  },
  created () {
    window.that = this;
    this.isEdit = this.$store.getters.getIsEdit;
    this.win_width = document.documentElement.scrollWidth || 1920;
  },
  mounted () {
    this.fun1()
    this.fun2()
    this.$listen.listen('deliverySystemImageText', e => {
      this.win_width = e;
      this.resizeFun()
    })
  },
  data () {
    return {
      isEdit: false,
      win_width: 1920,
      changeStatus: 0,
      myChart: null,
      myChart_: null,
    }
  },
  watch: {
    '$store.state.changeStatus' (new_, old_) {
      this.resizeFun()
    }
  },
  methods: {
    resizeFun: _lodash.throttle(function () {
      console.log('觸發刷新');
      // this.myChart.resize()
      // this.myChart_.resize()
      this.fun1();
      this.fun2();
    }, 3000),
    fun1 () {
      let fontSize = this.win_width <= 500 ? '12' : this.win_width <= 1000 ? '10' : '16';
      let width_ = this.win_width <= 500 ? '5' : '5'
      if (!this.myChart)
        this.myChart = echarts.init(document.getElementById('main1'));

      // 處理數據
      let nowL = this.nowL;
      let configs = this.configs;
      let items = configs.items[0];
      let xAxisData = items.xAxisData;
      let seriesData = items.seriesData;
      // 兼容新舊數據
      if (typeof xAxisData[0] === 'object') {
        let arr = [];
        let sarr = [];
        for (let i = 0; i < xAxisData.length; i += 1) {
          let xa = xAxisData[i];
          arr.push(xa[`${nowL}text`] || xa.text)
          sarr.push(xa.value)
        }
        console.log(nowL);
        xAxisData = arr;
        seriesData = sarr;
      }

      this.myChart.setOption({
        grid: {
          top: '2%',
          left: '-4%',
          right: '4%',
          bottom: '5%',
          containLabel: true
        },
        backgroundColor: "#fff",
        title: {
          text: ''
        },
        tooltip: {
        },
        xAxis: {
          data: xAxisData,
          axisTick: {
            show: false, // 隱藏刻度線
          },
          axisLine: {
            show: false, // 隱藏底部線
          },
        },
        yAxis: {
        },
        textStyle: {
          color: "#fafafa",
          fontSize: fontSize,
        },
        series: [{
          grid: {
            top: '10%',
            left: '0',
            right: '0',
            bottom: '20%',
            containLabel: true
          },
          color: ['red'],
          name: '销量',
          type: 'bar',
          barWidth: '40%',
          data: seriesData,
          tooltip: {
            show: false,
          },
          itemStyle: {
            emphasis: {
              color: "#EDF2FE"
            },
            normal: {
              color: '#EDF2FE',
              // label: {
              //   show: false, //开启显示
              //   position: 'top', //在上方显示,
              //   formatter: function (params) {
              //     return params.name
              //   }
              // },
              lineStyle: {
                width: 3,//折线宽度
                color: "#FF0000"//折线颜色
              }
            }
          }
        },
        {
          label: {
            show: true, //开启显示
            position: 'bottom', //在上方显示,
            offset: [0, (this.configs.items[1].lineNumber || 5)],
            width: width_,
            formatter: function (params) {
              let name = params.name;
              if (name) name = name.replace(/\\n/g, '\n')
              return name
            }
          },
          smooth: true, // 线条平滑展示，折线图起作用
          name: '',
          type: 'line',
          barWidth: '60%',
          // 'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow', 'none'
          symbol: 'pin',//拐点样式
          symbolSize: 0,//拐点大小
          data: seriesData.map((index) => {
            return index + (this.configs.items[0].lineNumber || 5)
          }),
          emphasis: {
            lineStyle: {
              width: width_,
              color: "#D2DDF0"
            },
          },
          itemStyle: {
            normal: {
              lineStyle: {
                width: width_,//折线宽度
                color: "#D2DDF0"//折线颜色
              },
            }
          },
          markPoint: {
            data: [
              {
                type: 'max', name: 'max',
                symbol: `image://${require('../../assets/testImg/箭头_列表向左.png')}`,
                tooltip: {
                  show: false,
                },
                label: {
                  show: false,
                },
                symbolSize: 40, // 圖片大小
                symbolRotate: this.configs.items[0].main2SymbolRotate,// 旋轉角度
                // symbolOffset: this.configs.items[0].main2SymbolOffset, // 偏移
                emphasis: {
                  disabled: false,
                },
              },
            ]
          }
        }
        ],
      });
    },
    fun2 () {
      let fontSize = this.win_width <= 500 ? '12' : this.win_width <= 1000 ? '10' : '16';
      let width_ = this.win_width <= 500 ? '5' : '5'
      if (!this.myChart_)
        this.myChart_ = echarts.init(document.getElementById('main2'));

      // 處理數據
      let nowL = this.nowL;
      let configs = this.configs;
      let items = configs.items[1];
      let xAxisData = items.xAxisData;
      let seriesData = items.seriesData;
      // 兼容新舊數據
      if (typeof xAxisData[0] === 'object') {
        let arr = [];
        let sarr = [];
        for (let i = 0; i < xAxisData.length; i += 1) {
          let xa = xAxisData[i];
          arr.push(xa[`${nowL}text`] || xa.text)
          sarr.push(xa.value)
        }
        xAxisData = arr;
        seriesData = sarr;
      }
      console.log(xAxisData);
      console.log(seriesData);

      this.myChart_.setOption({
        grid: {
          top: '2%',
          left: '0',
          right: '4%',
          bottom: '5%',
          containLabel: true
        },
        backgroundColor: "#fff",
        title: {
          text: ''
        },
        tooltip: {
        },
        xAxis: {
          data: xAxisData,
          axisTick: {
            show: false, // 隱藏刻度線
          },
          axisLine: {
            show: false, // 隱藏底部線
          },
        },
        yAxis: {
        },
        textStyle: {
          color: "#fafafa",
          fontSize: fontSize,
        },
        series: [
          {
            grid: {
              top: '10%',
              left: '0',
              right: '0',
              bottom: '20%',
              containLabel: true
            },
            color: ['red'],
            name: '销量',
            type: 'bar',
            barWidth: '40%',
            data: seriesData,
            tooltip: {
              show: false
            },
            itemStyle: {
              emphasis: {
                color: "#EDF2FE"
              },
              normal: {
                color: '#EDF2FE',
                tooltip: {
                },
                label: {
                  show: false, //开启显示
                  position: 'top', //在上方显示,
                  formatter: function (params) {
                    let name = params.name;
                    if (name) name = name.replace(/\\n/g, '\n')
                    return name
                  }
                },
                lineStyle: {
                  width: 3,//折线宽度
                  color: "#FF0000"//折线颜色
                }
              }
            },
          },
          {
            label: {
              show: true, //开启显示
              position: 'bottom', //在上方显示,
              offset: [0, (this.configs.items[1].lineNumber || 5)],
              width: width_,
              formatter: function (params) {
                let name = params.name;
                if (name) name = name.replace(/\\n/g, '\n')
                return name
              }
            },
            tooltip: {
              show: false
            },
            smooth: true, // 线条平滑展示，折线图起作用
            name: '',
            type: 'line',
            barWidth: '60%',
            // 'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow', 'none'
            symbol: 'pin',//拐点样式
            symbolSize: 0,//拐点大小
            data: seriesData.map((index) => {
              return index + (this.configs.items[1].lineNumber || 5)
            }),
            emphasis: {
              lineStyle: {
                width: width_,
                color: "#D2DDF0"
              },
            },
            itemStyle: {
              normal: {
                lineStyle: {
                  width: width_,//折线宽度
                  color: "#D2DDF0"//折线颜色
                },
              },
            },
            markPoint: {
              data: [
                {
                  type: 'max', name: 'max',
                  symbol: `image://${require('../../assets/testImg/箭头_列表向左.png')}`,
                  tooltip: {
                    show: false,
                  },
                  label: {
                    show: false,
                  },
                  symbolSize: 40, // 圖片大小
                  symbolRotate: this.configs.items[1].main2SymbolRotate,// 旋轉角度
                  // symbolOffset: this.configs.items[1].main2SymbolOffset, // 偏移
                  emphasis: {
                    disabled: false,
                  },
                },
              ]
            },
          }
        ],
      });
    },
    clickItem (e, x) {
      if (this.isEdit) {
        this.$emit('clickItem', e, x);
      }
    }
  }
}
</script>

<style lang="less" scoped>
.QR_code_order_image_text1_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0 60px !important;
  .title {
    font-size: 30px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
  }
  .desc {
    font-size: 20px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    margin-top: 6px;
    color: #999;
  }
  .echarts-box {
    display: flex;
    align-items: center;
    margin-top: 40px;
    .echarts1 {
      position: relative;
      margin-left: 26px;
      background-color: #fafafa;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      padding: 100px 80px 90px 80px;
      #main1 {
        position: relative;
        right: -46px;
        z-index: 1;
        /deep/ div:first-child {
          box-shadow: 0px 5px 15px 1px rgba(0, 0, 0, 0.05);
        }
      }
      .echarts1-bg {
        position: absolute;
        bottom: 50px;
        left: 40px;
        // transform: translate(-50%, -48%);
        width: 50%;
        height: calc(100% - 190px);
        background-color: #fff;
        display: flex;
        flex-direction: column;
        box-shadow: 0px 5px 15px 1px rgba(0, 0, 0, 0.05);
        .px-circle {
          margin-left: 20px;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background-color: #f4f6f8;
          margin-top: 40px;
        }
        .child1 {
          margin-left: 20px;
          height: 40%;
          width: 30%;
          background-color: #f4f6f8;
          margin-top: 10px;
        }
        .child2 {
          margin-left: 20px;
          width: 30%;
          height: 40px;
          margin-top: 26px;
          background-color: #f4f6f8;
        }
      }
    }
    .echarts2 {
      position: relative;
      margin-left: 26px;
      background-color: #fafafa;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      padding: 100px 80px 90px 80px;
      #main2 {
        position: relative;
        z-index: 1;
        /deep/ div:first-child {
          box-shadow: 0px 5px 15px 1px rgba(0, 0, 0, 0.05);
        }
      }
      .echarts2-bg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -48%);
        width: 60%;
        height: calc(100% - 150px);
        background-color: #fff;
        padding: 20px 20px 15px 20px;
        box-shadow: 0px 5px 15px 1px rgba(0, 0, 0, 0.05);
        .child {
          position: absolute;
          width: calc(100% - 40px);
          height: calc(100% - 35px);
          background-color: #efeff1;
          border-radius: 6px;
        }
      }
    }
  }
}

@media screen and(max-width:1000px) {
  .px-QR_code_order_image_text1_box {
    .title {
      font-size: 16px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
    }
    .desc {
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      margin-top: 2px;
    }
  }
}

@media screen and (max-width: 500px) {
  .px-QR_code_order_image_text1_box {
    padding: 25px 13px 25px 13px !important;
    .title {
      font-size: 15px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      text-align: center;
    }
    .desc {
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      margin-top: 2px;
      text-align: center;
    }
    .echarts-box {
      margin-top: 23px;
      flex-direction: column;
      .echarts1 {
        position: relative;
        margin-left: 0;
        #main1 {
          width: 80vw !important;
          height: 80vw !important;
        }
        .echarts1-bg {
          position: absolute;
          bottom: 10px;
          left: 10px;
          // transform: translate(-50%, -48%);
          width: calc((100vw / 2.89) / 2);
          height: calc((100vw / 2.89));
          background-color: #fff;
          display: flex;
          flex-direction: column;
          box-shadow: 0px 5px 15px 1px rgba(0, 0, 0, 0.05);
          .px-circle {
            margin-left: 6px;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: #f4f6f8;
            margin-top: 14px;
          }
          .child1 {
            margin-left: 6px;
            height: 36%;
            width: 30%;
            background-color: #f4f6f8;
            margin-top: 10px;
          }
          .child2 {
            margin-left: 6px;
            width: 30%;
            height: 12px;
            margin-top: 12px;
            background-color: #f4f6f8;
          }
        }
      }
      .echarts2 {
        margin-left: 0;
        #main2 {
          width: 80vw !important;
          height: 80vw !important;
        }
      }
    }
  }
}
</style>