var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"QR_code_order_image_text1_box px-QR_code_order_image_text1_box",style:(`
      padding-top: ${_vm.configs.paddingTop}px;
  padding-bottom: ${_vm.configs.paddingBottom}px;
  background-color:${_vm.configs.backgroundColor};
`),on:{"click":function($event){return _vm.clickItem('')}}},[_c('h1',{staticClass:"title",class:`edit_${_vm.Xindex}_title`,domProps:{"innerHTML":_vm._s(_vm.$util.rex.getHtml(_vm.configs[`${_vm.nowL}title`] || _vm.configs.title))}}),_c('h2',{staticClass:"desc",class:`edit_${_vm.Xindex}_desc`,domProps:{"innerHTML":_vm._s(_vm.$util.rex.getHtml(_vm.configs[`${_vm.nowL}desc`] || _vm.configs.desc))}}),_c('div',{staticClass:"echarts-box"},[_c('div',{staticClass:"echarts1",on:{"click":function($event){$event.stopPropagation();return _vm.clickItem('items',1)}}},[_c('div',{style:(`width: calc(100vw / 2.89); 
        height: calc(100vw / 2.89)`),attrs:{"id":"main1"}}),_vm._m(0)]),_c('div',{staticClass:"echarts2",on:{"click":function($event){$event.stopPropagation();return _vm.clickItem('items',2)}}},[_c('div',{style:(`width: calc(100vw / 2.89); 
        height: calc(100vw / 2.89)`),attrs:{"id":"main2"}}),_vm._m(1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"echarts1-bg"},[_c('div',{staticClass:"px-circle"}),_c('div',{staticClass:"child1"}),_c('div',{staticClass:"child2"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"echarts2-bg"},[_c('div',{staticClass:"child"})])
}]

export { render, staticRenderFns }